import React, { useState, useEffect } from "react";
import $ from "jquery";
import { Link } from "gatsby";
import qs from "qs"
import { Col, Container, Row, Form } from "react-bootstrap";
import Select from 'react-select'
import { isEmpty, filter, find, isArray, isString, get, indexOf } from "lodash"
import { format } from 'date-fns'

import { DateRange } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { Pagination, connectPagination } from 'react-instantsearch-dom';
import SeoResults, { ACTION_TYPES, ACTION_DESC_TYPES, getPriceStr } from "../components/property-search/seoResults"
import { parseSearchUrl, propertyH1, propertyDesc, propertyDescIntroCopy } from "../components/property-search/utils";
import { amenitiesCheckboxArray, VirtualViewing, RentalPeriod, Furnishing, bathroomsRange, ParkingRange, resiSalesPropertyType, resiLettingsPropertyType, priceminRangeResiSale, pricemaxRangeResiSale, priceminRangeResiRent, pricemaxRangeResiRent, bedroomsRange, bedroomsMinRange, bedroomsMaxRange, sqftRange } from "../components/common/propertyUtils";
import PropertyNoResult from "../components/property-noresult/property-noresult"
import PaginationPanel from "../components/PaginationPanel/PaginationPanel";
import PropertyFilter from '../components/PropertyFilter/PropertyFilterHolidayLets'
import PropertySlider from '../components/PropertySlider/PropertySlider'
import algoliasearch from "algoliasearch/lite"
import SEO from "../components/seo"
import { isMobile } from 'react-device-detect'
// 
import {
    connectStats,
    SortBy,
    connectHits,
    connectInfiniteHits,
    InstantSearch,
    RefinementList,
    connectSearchBox,
    connectMenu,
    connectRange,
    Configure,
    ToggleRefinement,
    connectSortBy,
    connectAutoComplete,
    ClearRefinements,
    connectToggleRefinement
} from "react-instantsearch-dom"
import { navigate } from "@reach/router"
import '../components/SearchSelect/SearchSelect.scss';
import { numberFormat } from "../common/site/utils";



// 
const updateAfter = 500
const DEBOUNCE_TIME = 500;

var minpriceval = minpriceval || ""
var maxpriceval = maxpriceval || ""

var includeSold = false;
var includeLet = false;

var checkinCheckoutDate = ''

const searchClient = algoliasearch(
    process.env.GATSBY_ALGOLIA_APP_ID,
    process.env.GATSBY_ALGOLIA_SEARCH_API_KEY
)
const index_name = `${process.env.GATSBY_ALGOLIA_INDEX_NAME}`
// 
var defaultArea = "dubai"

// Property results loop
const InfiniteHits = ({ hits, hasPrevious, refinePrevious, hasMore, refineNext, location, userObjects }) => {

    var url_main = typeof window !== 'undefined' ? window.location.pathname : ''
    var page = url_main.split("/").filter(c => c.includes("page-")).length > 0 ? url_main.split("/").filter(c => c.includes("page-"))[0].split("page-").map(c => c)[1] : 1

    return (
        <>
            {(hits.length > 0 && hasPrevious) && (
                <button
                    id="myprevbut"
                    onClick={refinePrevious}
                    disabled={!hasPrevious}
                    className="d-none property_show_more_btn"
                >
                    Show prev
                </button>
            )}
            <Row>


                {
                    hits.map((hit, i) => {
                        // property details url structure
                        let uriStr = "holiday-lets-details/";
                        let mysale = "";

                        // property details url structure
                        return (
                            <Col md={6} lg={3} className="results_page_column">
                                <PropertySlider
                                    price={hit.price && hit.price != null ?  numberFormat(hit.price) : ''}
                                    propertyid={hit.objectID}
                                    title={hit.title}
                                    location={hit.display_address}
                                    description={hit.description}
                                    bedroomsCount={hit.bedroom}
                                    currency={hit.currency}
                                    bathroomsCount={hit.bathroom}
                                    receptionsCount={hit.reception}
                                    propertyVideo={hit.virtual_tour}
                                    propertyImages={hit.images}
                                    thumb={hit.thumbnail}
                                    propertyDetailsLink={`/${uriStr}${hit.slug}-${hit.objectID}/`}
                                    propertySearchType={hit.searchType}
                                    priceQualifier={hit.price_qualifier}
                                    price_on_request={hit.price_on_request}
                                    status={hit.status}
                                    sqft={hit.size}
                                    userObjects={userObjects}
                                    holiday={true}
                                />
                            </Col>
                        )
                    })
                }
            </Row>

            {hits.length > 0 && (
                <div className="load-more-wrap">
                    <CustomPaginationRender pagenumber={parseInt(page)} />
                    {/* <div className="form__actions m-0">
                        <button className="d-flex btn btn--fill-lightblue"
                            id="mynextbut"
                            onClick={refineNext}
                            disabled={!hasMore}
                        >
                            <div>Load More</div><i className="icon-right"></i>
                        </button>
                    </div> */}
                </div>
            )}
            

        </>
    )
}

const CustomInfiniteHits = connectHits(InfiniteHits)
// Property results loop



const CustomPagination = ({ currentRefinement, nbPages, refine, createURL, pagenumber }) => {
    useEffect(() => {
        document.querySelectorAll(".ais-Pagination a.ais-Pagination-link").forEach(node => {
          // node.href.setAttribute('href', 'javascript:void(0)')
         node.href="javascript:void(0)"
        })
      }, [])
    useEffect(() => {
        if (parseInt(nbPages) <= parseInt(pagenumber)) {
            $('.page-next').attr('disabled', true);
        }
        else {
            $('.page-next').attr('disabled', false);
        }

        if (parseInt(pagenumber) == 1) {
            $('.page-back').attr('disabled', true);
        }
        else {
            $('.page-back').attr('disabled', false);
        }
    }, [pagenumber])


    $(document).on('click', '.ais-Pagination-link', function () {
        if (window) {
            window.scroll({
                top: -100,
                behavior: 'smooth'
            });
        }
    });
    $("a.ais-Pagination-link").attr("href", "javascript:void(0)")
    
    // Pagination select box
    const paginationselect = () => {
        var page_val = document.getElementById('pagination-select').value
        navigate(createURL(page_val))
        refine(page_val);

        if (window) {
            window.scroll({
                top: -100,
                behavior: 'smooth'
            });
        }
    }
    // Pagination select box

    // Next Pagination Button
    const nextpage = () => {
        const url = typeof window !== 'undefined' ? window.location.href : ''
        let pathUri = url.split("page-")
        var next_page_number = parseInt(pagenumber) + 1
        navigate(pathUri[0]+"page-"+next_page_number+"/")
        refine(next_page_number);

        if (window) {
            window.scroll({
                top: -100,
                behavior: 'smooth'
            });
        }
    }
    // Next Pagination Button

    // Previous Pagination Button
    const prevpage = () => {
        const url = typeof window !== 'undefined' ? window.location.href : ''
        let pathUri = url.split("page-")
        var prev_page_number = parseInt(pagenumber) - 1
        navigate(pathUri[0]+"page-"+prev_page_number+"/")
        refine(prev_page_number);

        if (window) {
            window.scroll({
                top: -100,
                behavior: 'smooth'
            });
        }
    }
    // Previous Pagination Button

    return (
        <Row>
            <Col md={3} className="d-none d-md-block">
                <button className="page-back" onClick={() => prevpage()}><i className="page-arrow-back"></i> Previous</button>
            </Col>
            <Col md={6} className="text-center">
                <Pagination
                    showFirst={false}
                    showPrevious={false}
                    showNext={false}
                    ariaPrevious='Previous page'
                    first='Next page'
                    showLast={false}
                    // defaultRefinement={props.page}
                    padding={2}
                />
            </Col>
            <Col md={3} className="d-none d-md-block">
                <button className="page-next" onClick={() => nextpage()}>Next <i className="page-arrow-next"></i></button>
            </Col>
        </Row>
    )
}

const CustomPaginationRender = connectPagination(CustomPagination);


// Capitalize
const capitalize = (s) => {
    if (typeof s !== 'string') return ''
    return s.charAt(0).toUpperCase() + s.slice(1)
}
// Capitalize

// Searchbox
const SearchBox = connectSearchBox(({ currentRefinement, refine }) => {
    return (
        <div className="filter-search-input-box">
            <div className="dropdown">
                {/* <Select className="react-select" classNamePrefix="react-select" defaultValue={optionsLocation[0]} options={optionsLocation} isSearchable={false} /> */}
                <i className="icon-location"></i>
                <Form.Control className='react-select' type="search" placeholder="Street, area or postcode" id="formBasicEmail"
                    onChange={event => refine(event.currentTarget.value.replace(/ /g, "-").toLowerCase())}
                    value={currentRefinement.replace(/-/g, ' ')}
                />
            </div>
        </div>
    )
})
// Searchbox


// Property Bedrooms
const adultSelectBox = ({
    currentRefinement,
    min,
    max,
    precision,
    refine,
}) => {



    return (
        <Select
            name="peopleCapacity"
            options={bedroomsRange}
            // value={{ value: "", label: "Any" }}
            placeholder="Adults Capacity"
            onChange={(e) => {
                refine({
                    min: e.value,
                })
            }}
            isSearchable={false}
        />
    )
}

const CustomadultSelectbox = connectRange(adultSelectBox)
// Property Bedrooms 


// Min Price
const DateSelectbox = ({
    currentRefinement,
    min,
    max,
    precision,
    refine,
    type
}) => {
    const [state, setState] = React.useState([
        {
            startDate: new Date(),
            endDate: new Date(),
            key: 'selection'
        }
    ]);

    React.useEffect(() => {
        var mind = format(new Date(state[0].startDate), "yyyy-MM-dd")
        var maxd = format(new Date(state[0].endDate), "yyyy-MM-dd")
        refine({
            min: Date.parse(mind),
            max: Date.parse(maxd),
        })
    }, [state])
    return (
        <div className='date-picker'>
            <DateRange
                editableDateInputs={true}
                onChange={item => setState([item.selection])}
                moveRangeOnFirstSelection={false}
                ranges={state}
                weekdayDisplayFormat="EEEEE"
                dateDisplayFormat="dd-MM-yyyy"
                showClearDate={true}
            />
            <div className='clear-date' onClick={() => setState([
                {
                    startDate: new Date(),
                    endDate: new Date(),
                    key: 'selection'
                }
            ])}>Clear Dates</div>
        </div>
    )
}

const CustomdateSelectbox = connectRange(DateSelectbox)
// Min Price


// Sort by
const sortbyStyle = {
    control: base => ({
        ...base,
        boxShadow: "none",
        border: "none",
    }),
}

const CustomSortBy = ({ items, refine, currentRefinement }) => (
    <Select
        isSearchable={false}
        styles={sortbyStyle}
        options={items}
        classNamePrefix={"react-select"}
        className={"react-select"}
        defaultValue={currentRefinement || ''}
        onChange={(e) => { refine(e.value, e.label) }}
        placeholder={
            <div className="custom-select__placeholder"> {"Most Recent"}</div>
        }
    />
);

const CustomSortByFilter = connectSortBy(CustomSortBy);
// Sort by


// No Results
const NoStats = ({ processingTimeMS, nbHits, location, status, department, OpenMember }) => {
    return (
        <>
            {nbHits === 0 &&
                <>

                    <div className="no-properties-text">
                        <p className="mb-0">Unfortunately, we do not currently have any properties that match your search criteria.</p>
                        <p>We have selected some of our showcase properties for you to browse below. Alternatively, you can search again in the bar above.
                        </p>
                        <PropertyNoResult uriStr={location} status={status} department={department} />
                    </div>
                </>
            }
        </>
    )
}

const CustomNoStats = connectStats(NoStats)
// No Results

// Results count
const Stats = ({ processingTimeMS, nbHits }) => <>{nbHits}</>

const CustomStats = connectStats(Stats)
// Results count

// Create URL
export const createURL = (props, state, location) => {
    let myarea = state.menu

    const isDefaultRoute =
        !state.query &&
        !state.SortBy &&
        state.page === 1 &&
        state.menu &&
        state.range &&
        !state.range.price &&
        !state.range.bedroom &&
        !state.range.bathroom


    if (isDefaultRoute) {
        return ""
    }

    var areaPath = "in-dubai/"
    var bedroomPath = ""
    var bathroomPath = ""
    var pricePath = ""
    var pagePath = ""
    var propertypath = ""
    var sortPath = ""
    var amenitiesPath = ""
    var sizePath = ""
    var furnishedPath = ""
    var viewingPath = ""
    var pqpath = ""
    var parkingPath = ""
    var adultPath = ""
    var childPath = ""
    var datePath = ""
    // if (state?.refinementList?.search_areas) {
    //   if (state?.refinementList?.search_areas) {
    //     areaPath = "in-" + encodeURIComponent(decodeURIComponent(state?.refinementList?.search_areas)) + "/"
    //   }
    // }
    if (state?.refinementList?.search_areas) {
        if (typeof state.refinementList.search_areas === "object") {
            if (state.refinementList.search_areas.length > 0) {
                var area_list = state.refinementList.search_areas.filter(function (item, index, inputArray) {
                    return inputArray.indexOf(item) == index;
                });
                if (area_list.length > 0) {
                    areaPath = `in-${area_list.map(encodeURIComponent).join('-and-')}/`
                }
            }
        } else {
            areaPath = `in-${state?.refinementList?.search_areas}/`
        }
        // if (state.refinementList?.amenities?.length > 0) {
        //   amenitiesPath = `with-${state.refinementList?.amenities}/`
        // }
    }
    const queryParameters = {}
    // Property path
    if (state.menu) {
        if (myarea["peopleCapacity"]) {
            propertypath = myarea["peopleCapacity"] + "-adults-or-more/"
        }
    }
    // if (state.range) {
        var pagepath = typeof window !== 'undefined' ? window.location.pathname : ''
        var pagepathsplit = pagepath.split("/")
        var checkinVal = ''
        var checkoutVal = ''
        var adultVal = ''
        var childVal = ''
        if (pagepathsplit.length > 0) {
            for (let vi = 1; vi <= pagepathsplit.length; vi++) {
                if (pagepathsplit[vi]?.indexOf("check-in-") >= 0) {
                    checkinVal = pagepathsplit[vi]?.replace("check-in-", "")
                }
                if (pagepathsplit[vi]?.indexOf("check-out-") >= 0) {
                    checkoutVal = pagepathsplit[vi]?.replace("check-out-", "")
                }
                if(pagepathsplit[vi]?.indexOf("-adults-or-more") >= 0){
                    adultVal = pagepathsplit[vi]?.replace("-adults-or-more", "")
                }

                if(pagepathsplit[vi]?.indexOf("-childrens-or-more") >= 0){
                    childVal = pagepathsplit[vi]?.replace("-childrens-or-more", "")
                }
        
            }
        }
        if (pagepath.indexOf("check-in-") >= 0 || pagepath.indexOf("check-out-") >= 0) {
            datePath = "check-in-" + checkinVal + "/check-out-" + checkoutVal + "/"
        }

        if (pagepath.indexOf("-adults-or-more") >= 0) {
            adultPath = +adultVal+"-adults-or-more/"
        }

        if (pagepath.indexOf("-childrens-or-more") >= 0) {
            childPath = +childVal+"-childrens-or-more/"
        }

      
    // }
    // Sort path
    if (state.sortBy) {
        if (state.sortBy === index_name + "_price_asc") {
            sortPath = "sortby-price-asc/";
        }
        if (state.sortBy === index_name + "_price_desc") {
            sortPath = "sortby-price-desc/";
        }
        if (state.sortBy === index_name + "_bedroom_asc") {
            sortPath = "sortby-bedroom-asc/";
        }
        if (state.sortBy === index_name + "_bedroom_desc") {
            sortPath = "sortby-bedroom-desc/";
        }
        if (state.sortBy === index_name + "_most_recent") {
            sortPath = "sortby-most-recent/";
        }
    }
    // Sort path

    // Page path
    if (state.page && state.page !== 1) {
        pagePath = `page-${state.page}/`
    }
    // Page path

    const queryString = qs.stringify(queryParameters, {
        addQueryPrefix: true,
        arrayFormat: "repeat",
    })


    let myUrl = `${areaPath}${propertypath}${adultPath}${childPath}${datePath}${bathroomPath}${pricePath}${sizePath}${amenitiesPath}${furnishedPath}${pqpath}${viewingPath}${parkingPath}${queryString}${sortPath}${pagePath}`


    // if ("/" === myUrl.substr(myUrl.length - 1))
    //   myUrl = myUrl.substr(0, myUrl.length - 1)
    //console.log("propsprops", props)
    if ("/" === myUrl.substr(myUrl.length - 1))
        myUrl = myUrl.substr(0, myUrl.length - 1)

    if (!myUrl.endsWith('/'))
        myUrl = myUrl + '/';

    myUrl = myUrl.toLowerCase();
    return `/holiday-lets/${myUrl}`

}
// Create URL

// URL to searchstate
const searchStateToUrl = (props, searchState, location) =>
    searchState ? `${createURL(props, searchState, location)}` : ""

function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

const urlToSearchState = location => {
    if (location) {
        var query = ""
        let pathUri_main = ''

        let a1 = location.pathname.indexOf("/holiday-lets") > -1
        let a2 = location.pathname.indexOf("/property/for-rent") > -1

        if (a1 == true) {
            pathUri_main = location.pathname.split("/holiday-lets")
        } else if (a2 == true) {
            pathUri_main = location.pathname.split("/property/for-rent")
        } else {
            pathUri_main = location.pathname.split(location)
        }

        var areaVal = ""
        var bedVal = 10
        var bathVal = 10
        var sqftVal = ""
        var minpriceVal = ""
        var maxpriceVal = ""
        var minbedVal = ""
        var maxbedVal = ""
        var pageVal = ""
        var propertyval = ""
        var sortVal = ""
        var sortVal_filt = ""
        var amenitiesval = ""
        var minsqftVal = ""
        var maxsqftVal = ""
        var furnishval = ""
        var parkingval = 10
        var viewingval = ""
        var price_qualifier_val = ""

        var adult_val = 0
        var child_val = 0
        var checkinVal = ""
        var checkoutVal = ""

        if (pathUri_main[1]) {
            // following could be regexp
            let pathUri = pathUri_main[1].split("/")

            //lets loop
            for (let vi = 1; vi <= pathUri.length; vi++) {
                // check for area
                if (typeof pathUri[vi] === "undefined") {
                    continue
                }
                // Area

                if (pathUri[vi].indexOf("in-") >= 0 && pathUri[vi].indexOf("in-") <= 1) {
                    let areaStr = pathUri[vi]
                    areaVal = areaStr.replace("in-", "")
                    if (areaVal === defaultArea) areaVal = ""
                    if (areaVal.includes("-and-")) {
                        areaVal = areaVal.split("-and-")
                    }
                }


                if (pathUri[vi].indexOf("-adults-or-more") >= 0) {
                    adult_val = parseInt(pathUri[vi].replace("-adults-or-more", ""))
                }

                if (pathUri[vi].indexOf("-childrens-or-more") >= 0) {
                    child_val = parseInt(pathUri[vi].replace("-childrens-or-more", ""))
                }

                if (pathUri[vi].indexOf("check-in-") >= 0) {
                    checkinVal = pathUri[vi].replace("check-in-", "")
                }
                if (pathUri[vi].indexOf("check-out-") >= 0) {
                    checkoutVal = pathUri[vi].replace("check-out-", "")
                }

                // Sort by
                if (pathUri[vi].indexOf("sortby-") >= 0) {
                    sortVal_filt = pathUri[vi].replace("sortby-", "")

                    if (sortVal_filt === "price-asc") {
                        sortVal = index_name + "_price_asc"
                    }

                    if (sortVal_filt === "price-desc") {
                        sortVal = index_name + "_price_desc"
                    }
                    if (sortVal_filt === "most-recent") {
                        sortVal = index_name
                    }
                }

                // Page
                if (pathUri[vi].indexOf("page") >= 0) {
                    pageVal = pathUri[vi].replace("page-", "")
                }
            }
        }
    }

    const getDatesInRange=(startDate, endDate,timeStamp=true)=> {
        // console.log("checkinVal1", startDate, endDate)
        const date = new Date(startDate);
        const enddate = new Date(endDate);
        // console.log("checkinVal11", date, enddate)
        const dates = [];
        while (date <= enddate) {
          if(timeStamp)
            dates.push(Date.parse(new Date(date).toJSON().slice(0,10))/1000);
          else{
            dates.push(new Date(date).toJSON().slice(0,10));
          }
          date.setDate(date.getDate() + 1);
        }
        return dates;
      }

      var checkInCheckOutTimestamp = checkinVal && checkoutVal ? getDatesInRange(checkinVal, checkoutVal) : ''
      checkInCheckOutTimestamp = checkinVal && checkoutVal ? "unAvailabilities!= "+checkInCheckOutTimestamp.join(" AND unAvailabilities!=") : ''


    // console.log("checkinVal33", checkInCheckOutTimestamp)
    
    // Property type
    var peoplecapacity = adult_val+child_val
    if (adult_val || child_val) {
        
        query += `&range[peopleCapacity][min]=` + parseInt(peoplecapacity)
    }
    if (checkinVal) {
        checkinCheckoutDate = ` AND `+checkInCheckOutTimestamp
        // query += `&filters=`+checkInCheckOutTimestamp
        // query += `&refinementList[unaAvailabilities]=${checkInCheckOutTimestamp}`
        // query += `&range[availabilities.EndDateTimeStamp][min]=` + Date.parse(checkinVal)
    }
    // if (checkoutVal) {
        // query += `&range[availabilities.EndDateTimeStamp][max]=` + Date.parse(checkoutVal)
        // query += `&range[availabilities.StartDateTimeStamp][max]=` + Date.parse(checkoutVal)
    // }
    // if (minpriceVal) {
    //     query += `&range[availabilities.EndDateTimeStamp][max]=` + minpriceVal
    // }
    // if (amenitiesval) {      
    //   query += `&refinementList[amenities]=` + amenitiesval
    // }   

    // if (amenitiesval) {
    //   for(let v=0; v <= amenitiesval.length; v++){
    //       if (typeof amenitiesval[v] !== "undefined") {
    //           query += `&refinementList[amenities]=` + amenitiesval[v]
    //       }
    //   }
    // }

    //console.log("search_areassearch_areas",areaVal)
    // Area
    if (areaVal) {
        if (isArray(areaVal)) {
            areaVal.map(area => {
                query += `&refinementList[search_areas]=${area.toLowerCase()}`
            })
        } else {
            query += `&refinementList[search_areas]=${areaVal}`
        }
    }
   // console.log(areaVal,"areaValues")
    // Page
    if (pageVal) {
        query += `&page=${pageVal}`
    }

    // Sort by
    if (sortVal) {
        query += `&sortBy=` + sortVal
    }

    // console.log("query", query)

    return qs.parse(query)
}
// URL to searchstate



class SearchResultsTemplateHoliday extends React.Component {

    constructor(props) {
        super(props);
    }
    // 
    state = {
        searchState: urlToSearchState(typeof window !== 'undefined' ? window.location : ''),
        refinementList: {
            amenities: [],
            search_areas: [],
        },
        userObjects: [],
        mapview: false,
        isOpen: false,
        popupType: "",
        membDet: ""
    }

    componentDidUpdate(prevProps) {
        if (prevProps.location !== this.props.location) {
            this.setState({ searchState: urlToSearchState(this.props.location) })
        }
    }
    onSearchStateChange = searchState => {
        clearTimeout(this.debouncedSetState)
        this.debouncedSetState = setTimeout(() => {
            navigate(searchStateToUrl(this.props, searchState, this.props.locationname), searchState)
        }, updateAfter)
        this.setState({ searchState })
    }
    componentDidMount() {
        $(document).mouseup(function (e) {
            var container = $(".price-filter-dropdown")
            // if the target of the click isn't the container nor a descendant of the container
            if (!container.is(e.target) && container.has(e.target).length === 0) {
                $(".price-filter-dropdown").removeClass("active")
            }
        })
        checkinCheckoutDate = ''
    }
    // 

    render() {
        const sortOptions = [{
            value: "Highest Price First",
            label: "Highest Price First"
        },
        {
            value: "Lowest Price First",
            label: "Lowest Price First"
        },
        ]
        const minPrice = [{ value: "", label: "Min Price" }]
        const maxPrice = [{ value: "", label: "Max Price" }]
        const bedroomsOpt = [{ value: "", label: "Bedrooms" }, { value: "1", label: "1+" }, { value: "2", label: "2+" }, { value: "3", label: "3+" }, { value: "4", label: "4+" }]
        const propertyTypes = [{ value: "", label: "Property Type" }, { value: "apartment", label: "Apartment" }, { value: "house", label: "House" }, { value: "flat", label: "Flat" }]
        // Capitalize
        const capitalize = (s) => {
            if (typeof s !== 'string') return ''
            return s.charAt(0).toUpperCase() + s.slice(1)
        }
        // Capitalize

        const searchParams = parseSearchUrl(this.props.ptype, this.props.fullpathname, this.props.pcategorytype);

        let propertyType = "";
        if (searchParams.type == "") {
            propertyType = "properties "
        } else {
            propertyType = searchParams.type
        }

        const pType = searchParams.pType;

        const actionStr = ACTION_TYPES[pType];

        

        const isPageLoadBg = (typeof this.props.fullpathname === "undefined");


        const h1 = propertyH1(searchParams, this.props.pcategorytype);

        const h1desc = propertyDesc(searchParams, this.props.pcategorytype);
        const h1descintro = propertyH1(searchParams, this.props.pcategorytype);
        const areaName = searchParams.areas;

        var description = `Explore our latest collection of Short term rental ${propertyType.toLowerCase()}in ${searchParams.areas}. Contact Arabian Estates in ${searchParams.areas} to find the right properties for you.`
        if(areaName !== "dubai"){
            description = `Find the latest collection of short term rental in ${searchParams.areas}, Contact Arabian Estates in ${searchParams.areas}, to arrange a viewing.`
        }
        // const saveSearchParams = savedSearchParams(searchParams);

        var split_sort_by = this.props.location.pathname.split('sortby-');
        var sort_by = split_sort_by.length > 1 ? split_sort_by[1] : '';
        var replaceH1 = h1.replace(/-/g, ' ');
        //replaceH1 = replaceH1.replace("Essex", "Essex and Kent");
        replaceH1 = replaceH1.replace(/And/g, "and");
        replaceH1 = replaceH1.replace("Central a c heating", "Central A/C & Heating")
        var replaceh1SECINTRO = h1descintro.replace(/-/g, ' ');
        var replaceh1SEC = replaceH1.replace(/-/g, ' ');
        replaceh1SEC = replaceh1SEC.replace(/And/g, "and");
        replaceh1SEC = replaceh1SEC.replace("Central a c heating", "Central A/C & Heating")
        replaceh1SECINTRO = replaceh1SECINTRO.replace(/And/g, "and");
        replaceh1SECINTRO = replaceh1SECINTRO.replace("Central a c heating", "Central A/C & Heating")
        //replaceh1SEC = replaceh1SEC.replace("Essex", "Essex and Kent");
        var areaReplace = capitalize(areaName).replace("%20", " ")
        
        // let pathUri_main = ''

        // let a1 = location.pathname.indexOf("/holiday-lets") > -1

        // if (a1 == true) {
        //     pathUri_main = location.pathname.split("/holiday-lets")
        // }
        // if (pathUri_main[1]) {
        //     // following could be regexp
        //     let pathUri = pathUri_main[1].split("/")
        //     var checkinVal = ""
        //     var checkoutVal = ""
        //     //lets loop
        //     for (let vi = 1; vi <= pathUri.length; vi++) {
        //         if (pathUri[vi].indexOf("check-in-") >= 0) {
        //             checkinVal = pathUri[vi].replace("check-in-", "")
        //         }
        //         if (pathUri[vi].indexOf("check-out-") >= 0) {
        //             checkoutVal = pathUri[vi].replace("check-out-", "")
        //         }
        //     }
            
        // }
        return (
            <Container>
                <Row>
                    <Col>
                        {!isPageLoadBg &&
                            <SEO title={replaceH1} searchParams={searchParams} description={description} location={this.props.fullpathname} pcategorytype={this.props.pcategorytype} />
                        }

                        <InstantSearch
                            indexName={index_name}
                            searchClient={searchClient}
                            searchState={this.state.searchState}
                            onSearchStateChange={this.onSearchStateChange}
                            createURL={createURL}
                            routing="true"
                        >

                            <Configure
                                hitsPerPage={12}
                                filters={`publish:true AND search_type:'lettings' AND department:'short-lettings' ${checkinCheckoutDate}`}
                            />
                            
                            <PropertyFilter locationname={this.props.locationname} searchState={this.state.searchState} type={this.props.ptype} ptypetag={this.props.ptypetag} department={this.props.pcategorytype} sort_by={sort_by} sales={this.props.ptype === "sales" ? true : false} />

                            <div className='search-select d-none'>
                                <div className='search-wrapper'>
                                    <div className='location-search flex-grow-1'>
                                        <SearchBox />
                                        <CustomdateSelectbox attribute="unAvailabilities" />
                                        {/* <CustomdateSelectbox attribute="availabilities.StartDateTimeStamp" /> */}
                                        <CustomadultSelectbox attribute="peopleCapacity" />
                                        {/* <CustombuildingSelectbox attribute="building" type={this.props.ptype} department={this.props.pcategorytype} /> */}
                                    </div>
                                </div>
                            </div>
                            <div className="search-head">
                                <div className="property-sale">
                                    <div className="result-details">
                                        <div className="lft-content">
                                        <h1 className="h1">
                                                <span className="total-result">
                                                    <CustomStats />
                                                </span>{replaceH1}
                                            </h1>
                                            {/* <p>Explore our collection of {replaceh1SEC} with Arabian Estates Properties. To filter these results, use our filter above, or for more information about properties for {this.props.ptype == "sales" ? "sale" : "rent"} in Dubai, <Link to="/contact-arabian-estates/">please contact us</Link>.</p> */}
                                            <p>Are you planning a visit to Dubai and looking for holiday lets? Look no further! We have an array of short-term rental properties. Book holiday rental apartments in Dubai with Arabian Holidays and enjoy a delightful stay in the vibrant city.</p>


                                            {/* <p>
                                                Lorem ipsum dolor sit amet consectetur adipiscing elit quisque vitae nisl semper, aliquam nibh non...
                                                <Link to="/" className="read-more">
                                                    read more
                                                </Link>
                                            </p> */}
                                        </div>
                                        <div className="rtl-content d-none d-md-flex">
                                            <span className="sort-by flex-shrink-0">
                                                Sort by:
                                            </span>
                                            <CustomSortByFilter
                                                defaultRefinement={index_name}
                                                className="search-sory-by"
                                                items={[
                                                    { value: index_name, label: "Most Recent" },
                                                    { value: index_name + "_price_desc", label: "Highest Price First" },
                                                    { value: index_name + "_price_asc", label: "Lowest Price First" }
                                                ]}
                                            />

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <CustomInfiniteHits
                                location={this.props.fullpathname}
                                tag={this.props.ptypetag}
                                userObjects={this.props.userObjects}
                            />
                            <CustomNoStats
                                location={this.props.fullpathname}
                                status={this.props.pstatustype}
                                department={this.props.pcategorytype}
                            />


                        </InstantSearch>


                        {/* <PaginationPanel /> */}
                    </Col>
                </Row>
            </Container>
        )
    }
}

export default SearchResultsTemplateHoliday
import React from 'react'
import { Link } from "gatsby"
import { Container, Row, Col } from 'react-bootstrap'
import parse from "html-react-parser"
import Holiday from '../../images/holiday-lets.png';
import { AnimationOnScroll } from 'react-animation-on-scroll';
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import './BrowseProperty.scss';
import GenerateLink from "../../common/link/generate-link"

const BrowseProperty = (props) => {
    var css_add = ''
    if(props.module.add_new_text_with_image_module.layout_type == "left_align_image") {
        css_add = "left-align"
    }
    return (
        <section className='browse-property'>
            <Container>
                <Row>
                    <Col>
                        <AnimationOnScroll animateIn="animate__fadeIn" animateOnce={true}>
                            <div className={`rating-content ${css_add}`}>
                                <div className='rating-image flex-shrink-0 d-flex'>
                                    <GatsbyImage image={getImage(props.module.add_new_text_with_image_module.media_block.media_file?.url_sharp)} alt={props.module.add_new_text_with_image_module.text_block.title} />

                                    {/* <img src={props.module.add_new_text_with_image_module.media_block.media_file.url} alt={props.module.add_new_text_with_image_module.text_block.title} /> */}
                                </div>
                                <div className='rating-text'>
                                    <h3>{props.module.add_new_text_with_image_module.text_block.title}</h3>
                                    {parse(props.module.add_new_text_with_image_module.text_block.description)}
                                    {props.module.add_new_text_with_image_module.Button_CTA?.Secondary_Url ? 
                                    <Link aria-label={props.module.add_new_text_with_image_module.Button_CTA?.label} class="btn" to={props.module.add_new_text_with_image_module.Button_CTA?.Secondary_Url}>{props.module.add_new_text_with_image_module.Button_CTA?.label}</Link>
                                    :
                                    <GenerateLink class="btn" link={props.module.add_new_text_with_image_module.Button_CTA?.menu_item}>{props.module.add_new_text_with_image_module.Button_CTA?.label}</GenerateLink>
                                    }
                                </div>
                            </div>
                        </AnimationOnScroll>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default BrowseProperty